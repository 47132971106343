<template>
  <div style="margin: 10px 0; width: 100%; background-color: #ffffff">
    <van-nav-bar left-text="返回" left-arrow @click-left="onClickLeft" />
    <div style="padding: 20px">
      <p>{{ donateInfo.title }}</p>
      <br />
      <div v-html="donateInfo.text"></div>
      <!-- <p style="text-align: left;">感谢您的爱心捐赠！</p>
			<p style="text-indent:2em;text-align: left;">
				请您实名捐赠，以便在接收到您的捐款并确认到账后，为您提供捐赠证书和公益事业捐赠统一票据；请您在捐款时提供正确的电子邮箱地址，以便我们为您发送电子捐赠证书和公益事业捐赠统一电子票据。
			</p>
			<p style="text-indent:2em;text-align: left;">
				若您捐赠金额在50万元及以上，或要捐赠物资及其他非货币资产，以及有其他需要了解沟通的捐赠事宜，欢迎通过电话与我们联系。
			</p>
			<p style="text-indent:2em;text-align: left;">
				联系电话： 028-84350282（工作日时间）</p> -->
    </div>
  </div>
</template>

<script>
import apiUrls from "@api";
export default {
  data() {
    return {
		donateInfo: {}
	};
  },

  components: {},

  mounted() {
	  this.getInstructions()
  },

  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    getInstructions() {
      apiUrls.trainNum({ node: "zxjz_jzxz" }).then((res) => {
        this.donateInfo = res.results.data;
      });
    },
  },
};
</script>
<style></style>
